import { useMemo } from 'react'

import { ReactLenis as RootReactLenis } from '@studio-freight/react-lenis'
import { useLocation } from '@reach/router'

export const ReactLenis = ({ children }) => children

export const ReactLenisBackup = ({ children }) => {
	const location = useLocation()
	const options = useMemo(() => {
		const searchParams = new URLSearchParams(location.search)
		return { lerp: searchParams.has('lerp') ? Number(searchParams.get('lerp')) : 0.1 }
	}, [location.search])

	console.info('[SMOOTH SCROLL] Setting lerp to', options.lerp)

	return (
		<RootReactLenis options={options} root>
			{children}
		</RootReactLenis>
	)
}
