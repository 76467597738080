import { gsap } from 'gsap'

export default function Parallax(element, dataset) {
	if (!dataset.animationOffset) dataset.animationOffset = 0.2

	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: element,
			scrub: true,
		},
	})

	tl.fromTo(
		element,
		{ y: dataset.animationOffset * 0.5 * window.innerHeight },
		{ y: -dataset.animationOffset * 0.5 * window.innerHeight, ease: 'none' },
		0
	)

	if (tl.scrollTrigger.trigger.getBoundingClientRect().top < 0) {
		tl.progress(1)
	}

	return { tl }
}
