import { forwardRef } from 'react'

import cn from 'clsx'

import { cornerColorVariants } from '.'

const Left = forwardRef(({ color, outline, className }, ref) => {
	return (
		<div className="relative h-100% w-32 overflow-hidden">
			<svg
				ref={ref}
				width="37"
				height="56"
				viewBox="0 0 37 56"
				xmlns="http://www.w3.org/2000/svg"
				className={cn('absolute left-0 top-0', cornerColorVariants[`${color}${outline ? 'Outline' : ''}`], className)}
			>
				<path
					d="M35 1.5L35 54.5L15.194 54.5C11.9562 54.5 9.63018 54.4985 7.85726 54.3373C6.08749 54.1764 5.08011 53.8695 4.3821 53.4026C2.9479 52.4432 1.95403 50.9528 1.61953 49.26C1.45674 48.4362 1.56061 47.3882 2.09227 45.6926C2.62487 43.9939 3.51752 41.8459 4.76194 38.8569L15.7779 12.3971C17.6017 8.01628 18.3866 6.18887 19.6154 4.86287C20.74 3.64939 22.1354 2.71882 23.6879 2.14703C25.3843 1.52222 27.373 1.5 32.1183 1.5L35 1.5Z"
					strokeWidth="3"
				/>
			</svg>
		</div>
	)
})

export default Left
