import { gsap } from 'gsap'

import SplitText from '@utils/SplitText'

export default function Title(element, dataset) {
	const delay = dataset.animationDelay || 0
	const stTitle = new SplitText(element, { type: 'lines' })

	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: element,
		},
	})

	if (!element.classList.contains('overflow-hidden')) element.classList.add('overflow-hidden')
	tl.set(element, { opacity: 1 }, 0)
	if (stTitle?.lines) {
		stTitle.lines.forEach((line, i) => {
			tl.fromTo(line, { opacity: 0 }, { opacity: 1, duration: 0.1, ease: 'expo.out' }, i * 0.2 + delay)
			tl.fromTo(line, { y: 100 }, { y: 0, ease: 'power4.out', duration: 1.6, stagger: 0.1 }, i * 0.2 + delay)
		})
	}

	if (tl.scrollTrigger.trigger.getBoundingClientRect().top < 0) {
		tl.progress(1)
	}

	const onResize = () => {
		stTitle?.onResize()
	}

	const destroy = () => {
		window.removeEventListener('resize', onResize)
	}

	window.addEventListener('resize', onResize)

	return { tl, destroy }
}
