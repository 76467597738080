import { Link } from 'gatsby'

const InternalLink = ({ children, target = {}, className, outerElement: Outer, ...rest }) => {
	return (
		<Link to={target.fullPath} className={className} {...rest}>
			{Outer ? <Outer>{children || target.title}</Outer> : children || target.title}
		</Link>
	)
}

export default InternalLink
