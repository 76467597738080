exports.components = {
  "component---src-templates-article-list-page-jsx": () => import("./../../../src/templates/ArticleListPage.jsx" /* webpackChunkName: "component---src-templates-article-list-page-jsx" */),
  "component---src-templates-article-page-jsx": () => import("./../../../src/templates/ArticlePage.jsx" /* webpackChunkName: "component---src-templates-article-page-jsx" */),
  "component---src-templates-card-list-page-jsx": () => import("./../../../src/templates/CardListPage.jsx" /* webpackChunkName: "component---src-templates-card-list-page-jsx" */),
  "component---src-templates-card-series-page-jsx": () => import("./../../../src/templates/CardSeriesPage.jsx" /* webpackChunkName: "component---src-templates-card-series-page-jsx" */),
  "component---src-templates-category-group-page-jsx": () => import("./../../../src/templates/CategoryGroupPage.jsx" /* webpackChunkName: "component---src-templates-category-group-page-jsx" */),
  "component---src-templates-category-page-jsx": () => import("./../../../src/templates/CategoryPage.jsx" /* webpackChunkName: "component---src-templates-category-page-jsx" */),
  "component---src-templates-event-list-page-jsx": () => import("./../../../src/templates/EventListPage.jsx" /* webpackChunkName: "component---src-templates-event-list-page-jsx" */),
  "component---src-templates-ip-list-page-jsx": () => import("./../../../src/templates/IpListPage.jsx" /* webpackChunkName: "component---src-templates-ip-list-page-jsx" */),
  "component---src-templates-ip-page-jsx": () => import("./../../../src/templates/IpPage.jsx" /* webpackChunkName: "component---src-templates-ip-page-jsx" */),
  "component---src-templates-not-found-jsx": () => import("./../../../src/templates/NotFound.jsx" /* webpackChunkName: "component---src-templates-not-found-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-preview-page-jsx": () => import("./../../../src/templates/PreviewPage.jsx" /* webpackChunkName: "component---src-templates-preview-page-jsx" */),
  "component---src-templates-product-list-page-jsx": () => import("./../../../src/templates/ProductListPage.jsx" /* webpackChunkName: "component---src-templates-product-list-page-jsx" */),
  "component---src-templates-product-page-jsx": () => import("./../../../src/templates/ProductPage.jsx" /* webpackChunkName: "component---src-templates-product-page-jsx" */),
  "slice---src-components-layout-footer-footer-jsx": () => import("./../../../src/components/layout/Footer/Footer.jsx" /* webpackChunkName: "slice---src-components-layout-footer-footer-jsx" */),
  "slice---src-components-layout-header-header-jsx": () => import("./../../../src/components/layout/Header/Header.jsx" /* webpackChunkName: "slice---src-components-layout-header-header-jsx" */)
}

