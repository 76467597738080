import { ANIMATION_DELAY } from '@animations'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default function AppearX(element, dataset) {
	const delay = ANIMATION_DELAY + (parseFloat(dataset.animationDelay) || 0)

	let tl

	if (dataset.animationBatch) {
		tl = ScrollTrigger.batch(element.children, {
			onEnter: (batch) => {
				const tl = gsap.timeline()

				tl.fromTo(
					batch,
					{ x: window.innerWidth },
					{ x: 0, immediateRender: false, duration: 1.8, ease: 'immgExpoOut', stagger: 0.12 },
					delay
				)
				tl.fromTo(batch, { alpha: 0 }, { alpha: 1, duration: 0.6, stagger: 0.12 }, delay)
			},
			once: true,
		})
		gsap.set(element.children, { alpha: 0 }, 0)
	} else {
		tl = gsap.timeline({
			scrollTrigger: {
				trigger: element,
			},
		})

		tl.set(element, { alpha: 0 }, 0)
		tl.fromTo(
			element,
			{ x: window.innerWidth },
			{ y: 0, immediateRender: false, duration: 1.8, ease: 'immgExpoOut' },
			delay
		)
		tl.fromTo(element, { alpha: 0 }, { alpha: 1, duration: 0.6, stagger: 0.12 }, delay)

		if (tl.scrollTrigger.trigger.getBoundingClientRect().top < 0) {
			tl.progress(1)
		}
	}

	return { tl }
}
