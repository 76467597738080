import '@styles/global.css'

import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { Flip } from 'gsap/Flip'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { ReactLenis } from '@components/layout/ReactLenis'
import Layout from '@components/layout/Layout'
import { CartProvider } from '@context/cartContext'
import { PreviewProvider } from '@context/previewContext'

export const onClientEntry = () => {
	gsap.registerPlugin(ScrollToPlugin, Flip, ScrollTrigger, CustomEase)
	gsap.defaults({
		ease: 'none',
		duration: 0.4,
	})
	gsap.config({
		force3D: true,
	})

	CustomEase.create('immgZoomIn', '0.9, 0.0, 0.4, 1.0')
	CustomEase.create('immgZoomOut', '0.4, 0.0, 0.1, 1.0')
	CustomEase.create('immgPosIn', '0.4, 0.0, 0.1, 1.0')
	CustomEase.create('immgPosOut', '0.9, 0.0, 0.4, 1.0')
	CustomEase.create('immgExpoOut', '0.14, 1.0, 0.34, 1.0')
	CustomEase.create('immgExpoIn', '0.66, 0.0, 0.86, 0.0')
}

export const wrapPageElement = ({ element, props }) => {
	// let's keep the original 404 page
	if ('custom404' in props) return element

	if (props.location.pathname.includes('/_preview'))
		return (
			<ReactLenis root>
				<PreviewProvider>{element}</PreviewProvider>
			</ReactLenis>
		)

	return (
		<ReactLenis root>
			<Layout {...props}>{element}</Layout>
		</ReactLenis>
	)
}

export const wrapRootElement = ({ element }) => {
	return <CartProvider>{element}</CartProvider>
}

// TODO : to uncomment when using page transitions
// export const shouldUpdateScroll = ({ routerProps }) => {
// 	if (routerProps.location.action === 'PUSH') {
// 		return false
// 	}
// 	return true

export const onPreRouteUpdate = ({ location }) => {
	window.___IS_PUSH = location.action === 'PUSH'
}
