import { forwardRef } from 'react'

import cn from 'clsx'

import ButtonContent from './Subcomponents/ButtonContent'

const Button = forwardRef(
	(
		{
			children,
			shape = 'left',
			color = 'orange',
			outline,
			loading,
			className,
			classNameContent,
			classNameContentDiv,
			disabled,
			isPaginationButton,
			...rest
		},
		ref
	) => {
		return (
			<button
				ref={ref}
				disabled={disabled || loading}
				className={cn(className, { 'pointer-events-none': disabled || loading }, 'focus:outline-none')}
				{...rest}
			>
				<ButtonContent
					shape={shape}
					color={color}
					outline={!!outline}
					disabled={disabled}
					classNameContentDiv={classNameContentDiv}
					loading={loading}
					isPaginationButton={!!isPaginationButton}
					classNameContent={classNameContent}
				>
					{children}
				</ButtonContent>
			</button>
		)
	}
)

export default Button
