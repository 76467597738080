import { useEffect } from 'react'

import { useLenis } from './useLenis'

const useLenisScrollRestore = (location) => {
	const lenis = useLenis()
	useEffect(() => {
		if (location.action === 'PUSH') window.scrollTo(0, 0)
		lenis.start()

		return () => lenis.stop()
	}, [lenis, location])

	return lenis
}

export default useLenisScrollRestore
