import { ANIMATION_DELAY } from '@animations'
import { gsap } from 'gsap'

export default function Fade(element, dataset) {
	const delay = ANIMATION_DELAY + (dataset.animationDelay || 0)

	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: element,
		},
	})

	tl.fromTo(element, { opacity: 0 }, { opacity: 1, ease: 'none', duration: 0.4 }, delay)

	if (tl.scrollTrigger.trigger.getBoundingClientRect().top < 0) {
		tl.progress(1)
	}

	return { tl }
}
