import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const GatsbyImageWrapper = ({ src, alt, className, objectFit, objectPosition, ...rest }) => {
	const gatsbySrcData = getImage(src)

	return (
		<GatsbyImage
			image={gatsbySrcData}
			alt={alt}
			className={className}
			{...rest}
			objectPosition={objectPosition}
			objectFit={objectFit}
		/>
	)
}

export default GatsbyImageWrapper
