import { ANIMATION_DELAY } from '@animations'
import { gsap } from 'gsap'

export default function FadeOut(element, dataset) {
	const delay = ANIMATION_DELAY + (parseFloat(dataset.animationDelay) || 0)

	let tl = gsap.timeline()

	tl.fromTo(
		element,
		{ alpha: 1 },
		{ alpha: 0, immediateRender: false, duration: 0.4, ease: 'none', stagger: 0.05 },
		delay
	)

	return { tl }
}
