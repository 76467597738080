import { forwardRef } from 'react'

import cn from 'clsx'

import * as styles from './Stepper.module.css'

const Stepper = forwardRef(function Stepper({ value, className, onChange, min = 1, max = 99, disabled }, ref) {
	const handleClickDec = () => {
		onChange(Math.max(min, value - 1))
	}

	const handleClickInc = () => {
		onChange(Math.min(max, value + 1))
	}

	const handleChange = (e) => {
		onChange(~~e.target.value)
	}

	return (
		<div
			className={cn(
				'component-stepper flex flex-row justify-between overflow-hidden rounded-5 border-1 border-black',
				styles.wrapper,
				className
			)}
		>
			<button
				onClick={handleClickDec}
				className="linear cursor-pointer transition-colors duration-100 hover:text-orange disabled:pointer-events-none disabled:cursor-default"
				disabled={disabled || value <= min}
			>
				-
			</button>
			<input
				ref={ref}
				type="number"
				min={min}
				max={max}
				className={cn('h-full text-center', styles.input)}
				value={value}
				onChange={handleChange}
				disabled={disabled}
			/>
			<button
				onClick={handleClickInc}
				className="linear cursor-pointer transition-colors duration-150 hover:text-orange disabled:pointer-events-none disabled:cursor-default"
				disabled={disabled || value >= max}
			>
				+
			</button>
		</div>
	)
})

export default Stepper
