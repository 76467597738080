import { forwardRef } from 'react'

import cn from 'clsx'

import { Left, Right, borderVariants, colorVariants, fontColorVariants, placeHolderVariants } from '../Subcomponents'

const Input = forwardRef(function Input(
	{
		type = 'text',
		shape = 'left',
		color = 'orange',
		outline = false,
		icon: Icon,
		className,
		inputClassName,
		error,
		hasShadow = false,
		...rest
	},
	ref
) {
	const theme = error ? 'error' : `${color}${outline ? 'Outline' : ''}`
	const cornerColor = error ? 'error' : color

	return (
		<div
			className={cn(
				'component-input',
				'relative flex h-56 mobile:w-full tablet:min-w-max',
				fontColorVariants[theme],
				{ 'drop-shadow-button': hasShadow },
				className
			)}
		>
			{shape !== 'left' && <Left color={cornerColor} outline={outline} />}
			<input
				ref={ref}
				type={type}
				className={cn(
					'ts-body -mx-1 h-full w-full border-0 border-b-3 border-t-3 border-solid p-16 outline-none mobile:flex-grow',
					colorVariants[theme],
					placeHolderVariants[theme],
					borderVariants[shape],
					inputClassName,
					{ 'border border-1 border-orange': error },
					{ 'pe-32': shape === 'right' && Icon },
					{ 'ps-32': shape === 'left' && Icon },
					{ 'ps-16': shape === 'left' && !Icon },
					{ 'ps-16': shape === 'left' && !Icon }
				)}
				{...rest}
			/>
			{shape !== 'right' && <Right color={cornerColor} outline={outline} className="translate-x-0 translate-y-0" />}
			{Icon && (
				<div
					className={cn('absolute left-0 top-0 flex h-100% -translate-x-1/3 items-center text-midnight', {
						'input-icon-error': error,
					})}
				>
					<Icon />
				</div>
			)}
			{error?.message && (
				<p className="absolute -bottom-4 left-0 translate-y-full pt-8 text-red tablet:-bottom-8 desktop:bottom-0 desktop:pt-16">
					{error.message}
				</p>
			)}
		</div>
	)
})

export default Input
