import { useCallback } from 'react'

import { useSettings } from './useSettings'

export const useCopy = () => {
	const settings = useSettings()
	const t = useCallback(
		(key, interpolation) => {
			const copy = settings?.copy || []
			const value = copy.find((c) => c.key === key)?.value.replace('\\n', '\n') || key
			if (interpolation) {
				return value.replace(/{{(\w+)}}/g, (_, k) => interpolation[k])
			}
			return value
		},
		[settings]
	)
	return { t }
}
