import { useCallback } from 'react'

import cn from 'clsx'
import { Link } from 'gatsby'

import Stepper from '@components/common/Form/Stepper/Stepper'
import Image from '@components/common/Image'
import { useCart, useRemoveItem, useUpdateItem } from '@context/cartContext'
import { useCopy } from '@hooks/useCopy'

import * as styles from './CartItem.module.css'

import RemoveIcon from '@svg/remove.svg'

const CartItem = ({ item, closeCart }) => {
	const { t } = useCopy()
	const { cart } = useCart()
	const cartRemove = useRemoveItem()
	const cartUpdate = useUpdateItem()

	const onClickRemove = useCallback(() => cartRemove({ product: item, lineItemId: item.id }), [item, cartRemove])
	const onUpdateQuantity = useCallback(
		(qty) => cartUpdate({ variantId: item.id, quantity: ~~qty }),
		[item.id, cartUpdate]
	)

	const image = cart.images[item.variant?.id]
	const path = cart.paths[item.variant?.id]

	const LinkOrDiv = path ? Link : 'div'
	const linkProps = path ? { to: path, onClick: closeCart } : {}

	return (
		<li>
			<RemoveIcon onClick={onClickRemove} className={cn('ml-auto mt-24 cursor-pointer', styles.icon)} />
			<div className="tablet:mt-24 tablet:flex tablet:gap-32">
				<LinkOrDiv className="border-image flex justify-center mobile:mt-24 tablet:basis-35%" {...linkProps}>
					{image ? (
						<Image src={image} className="mx-auto my-12 flex max-h-250 tablet:max-h-150" objectFit="contain" />
					) : (
						<Image
							src={item.variant?.image?.src}
							useVanillaImage
							className="mx-auto my-12 max-h-250 tablet:max-h-150"
						/>
					)}
				</LinkOrDiv>

				<div className="tablet:flex tablet:basis-65% tablet:flex-col tablet:justify-between">
					<LinkOrDiv {...linkProps} className="text-extrabold text-lg font-bold mobile:mt-24">
						{item.title}
					</LinkOrDiv>

					<div className="flex justify-between mobile:mt-24 tablet:flex-row-reverse">
						<div className="text-2xl font-black uppercase text-black">
							{t('cart.currencySign')}
							{Number(item.variant?.priceV2?.amount).toFixed(2)}
						</div>

						<Stepper disabled={cart.isUpdating} value={item.quantity} onChange={onUpdateQuantity} />
					</div>
				</div>
			</div>
		</li>
	)
}

export default CartItem
