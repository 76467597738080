import { useEffect, useRef } from 'react'

import * as animations from '@animations'

const EXCLUDED_ANIMATIONS = ['Title', 'AppearY', 'Fade']

export const useAnimations = (location) => {
	const el = useRef()

	useEffect(() => {
		if (!el.current) return
		const elements = el.current.querySelectorAll('[data-animation]')
		const anims = []

		elements.forEach((el) => {
			const name = el.dataset.animation.charAt(0).toUpperCase() + el.dataset.animation.substring(1)
			if (name in animations && !EXCLUDED_ANIMATIONS.includes(name)) anims.push(new animations[name](el, el.dataset))
		})

		return () => {
			anims.forEach((anim) => {
				anim.destroy?.()
				if (Array.isArray(anim.tl)) {
					anim.tl?.forEach((tl) => tl.kill())
				} else {
					anim.tl?.kill()
				}
			})
		}
	}, [location])

	return el
}
