export { default as Left } from './Left'
export { default as Right } from './Right'

export const cornerColorVariants = {
	orange: 'fill-orange stroke-orange',
	orangeOutline: 'fill-none stroke-orange group-hover:fill-orange',
	primary: 'fill-primary stroke-primary',
	primaryOutline: 'fill-none stroke-primary group-hover:fill-primary',
	secondary: 'fill-secondary stroke-secondary',
	secondaryOutline: 'fill-none stroke-secondary group-hover:fill-secondary',
	white: 'fill-white stroke-white',
	whiteOutline: 'fill-none stroke-white group-hover:fill-white',
	black: 'fill-black stroke-black',
	blackOutline: 'fill-none stroke-black group-hover:fill-black',
	gray: 'fill-gray stroke-gray group-hover:fill-orange group-hover:stroke-orange',
	error: 'fill-white stroke-red',
}

export const colorVariants = {
	orange: 'bg-orange border-orange',
	orangeOutline: 'bg-transparent border-orange group-hover:bg-orange',
	primary: 'bg-primary border-primary',
	primaryOutline: 'bg-transparent border-primary group-hover:bg-primary',
	secondary: 'bg-secondary border-secondary',
	secondaryOutline: 'bg-transparent border-secondary group-hover:bg-secondary',
	white: 'bg-white border-white',
	whiteOutline: 'bg-transparent border-white group-hover:bg-white',
	black: 'bg-black border-black',
	blackOutline: 'bg-transparent border-black group-hover:bg-black',
	gray: 'bg-gray border-gray group-hover:bg-orange group-hover:border-orange',
	error: 'bg-white border-red',
}

export const fontColorVariants = {
	orange: 'text-white group-hover:text-orange',
	orangeOutline: 'text-orange group-hover:text-white',
	white: 'text-midnight group-hover:text-white',
	whiteOutline: 'text-white group-hover:text-midnight',
	black: 'text-white group-hover:text-black',
	blackOutline: 'text-black group-hover:text-white',
	gray: 'text-midnight group-hover:text-white',
	error: 'text-red',
}

export const borderVariants = {
	left: ' rounded-tl-8 rounded-bl-8 rounded-br-0 rounded-tr-0 border-l-3',
	middle: '',
	right: 'rounded-tr-8 rounded-br-8 rounded-br-0 rounded-tr-0 border-r-3',
}

export const placeHolderVariants = {
	orange: 'placeholder-white',
	orangeOutline: 'placeholder-orange',
	white: 'placeholder-midnight',
	whiteOutline: 'placeholder-white',
	black: 'placeholder-white',
	blackOutline: 'placeholder-black',
	gray: 'placeholder-midnight',
	error: 'placeholder-red',
}
