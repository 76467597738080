import { graphql } from 'gatsby'

import ButtonContent from '@components/common/Form/Button/Subcomponents/ButtonContent'
import Link from '@components/common/Link/Link'

const ButtonOrLink = ({
	internalNickname, // filter out from props
	link,
	inverseOutline = false,
	appearance = 'Primary Button',
	color = 'orange',
	className,
	...rest
}) => {
	if (appearance === 'Link' && link?.internalOrExternal) return <Link className={className} {...link} color={color} />
	else
		return (
			<Link
				className="focus:outline-none mobile:w-full"
				{...link}
				button
				outerElement={({ children }) => (
					<ButtonContent
						color={color}
						className={className}
						inverseOutline={inverseOutline}
						{...rest}
						children={children}
					/>
				)}
			>
				{link?.title}
			</Link>
		)
}

export default ButtonOrLink

export const query = graphql`
	fragment ButtonOrLinkFragment on ContentfulComponentButtonOrLink {
		id
		appearance
		color
		link {
			...PageLinkFragment
		}
		shape
	}
`
