import cn from 'clsx'

import * as styles from './ButtonLoader.module.css'

export const colorVariants = {
	orange: 'bg-white',
	white: 'bg-black',
	black: 'bg-black',
	gray: 'bg-white',
}

const ButtonLoader = ({ color }) => {
	const bgColor = colorVariants[color] || 'bg-white'
	return (
		<span className="absolute flex w-full items-center justify-center ">
			<span className={cn(bgColor, styles.dot)} />
			<span className={cn(bgColor, styles.dot)} />
			<span className={cn(bgColor, styles.dot)} />
		</span>
	)
}

export default ButtonLoader
