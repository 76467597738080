import ExternalLinkIcon from '@svg/external-link.svg'

const ExternalLink = ({
	children,
	href,
	className,
	alt,
	externalLinkIcon = true,
	isAnchorLink = false,
	outerElement: Outer = ({ children }) => children,
}) => {
	const child = (
		<>
			{children}
			{!isAnchorLink && externalLinkIcon && (
				<ExternalLinkIcon className="relative ml-8 inline-block" style={{ width: '1em', top: '-0.1em' }} />
			)}
		</>
	)
	return (
		<a
			href={href}
			target={!isAnchorLink ? '_blank' : undefined}
			rel={!isAnchorLink ? 'noopener noreferrer' : undefined}
			className={className}
			alt={alt}
		>
			{Outer ? <Outer>{child}</Outer> : child}
		</a>
	)
}

export default ExternalLink
