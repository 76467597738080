const BR = ['<br/>', '<br>', '<BR/>', '<BR>', '&lt;br/&gt;']

export default class SplitText {
	constructor(el, params) {
		this.el = el
		this.params = {
			words: params.type.includes('words'),
			lines: params.type.includes('lines'),
		}

		this.text = this.el.innerText
		this.textHtml = this.el.innerHTML
		this.lines = []
		this.words = []

		this.splitWords()
		if (this.params.lines) this.splitLines()

		// set to false after opening animation
		this.cancelResize = true
	}

	splitWords = () => {
		const newContentWords = this.textHtml.split(' ').reduce((acc, val) => {
			return acc + (BR.includes(val) ? val : `<span class="st-word overflow-hidden">${val}</span> `)
		}, '')
		this.el.textContent = ''
		this.el.innerHTML = newContentWords
		this.words = this.el.querySelectorAll('.st-word')
		this.words.forEach((word) => {
			word.style.display = 'inline-block'
		})
	}

	splitLines = () => {
		let lines = []
		let lastOffset = null
		let lineIndex = -1
		let isBr = false
		this.words.forEach((word) => {
			if (lastOffset !== word.offsetTop) lineIndex++
			if (!lines[lineIndex]) lines[lineIndex] = []

			lastOffset = word.offsetTop
			if (isBr) return
			lines[lineIndex].push(word)
		})

		lines = lines.filter((line) => line.length)

		let newContentLines = ''
		lines.forEach((line) => {
			newContentLines += '<span class="st-line overflow-hidden">'
			line.forEach((word) => {
				if (this.params.words) newContentLines += word.outerHTML + ' '
				else newContentLines += word.innerHTML + ' '
			})
			newContentLines += '</span> '
		})
		this.el.innerHTML = newContentLines
		this.lines = this.el.querySelectorAll('.st-line')
		this.words = this.el.querySelectorAll('.st-word')
		this.lines.forEach((line) => {
			line.style.display = 'block'
		})
	}

	onResize = () => {
		if (this.cancelResize) return

		if (this.params.lines) {
			this.words = this.el.querySelectorAll('.st-word')
			if (!this.words.length) {
				this.splitWords()
			}
			this.splitLines()
		}
	}

	revert = () => {
		this.el.innerHTML = this.textHtml
		this.cancelResize = true
	}
}
