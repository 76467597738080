export const handleFindProductShopifyEntry = ({ shopifyProducts, id }) => {
	return shopifyProducts?.find((product) => product.storefrontId === 'gid://shopify/Product/' + id)
}

export const handleShopifyProductTranslations = (shopifyProducts, shopifyProductTranslations) => {
	const translations = shopifyProductTranslations?.reduce((acc, { node }) => {
		acc[node?.storefrontId] = node
		return acc
	}, {})

	return shopifyProducts?.map((product) => {
		const translation = translations[product?.storefrontId]
		return {
			...product,
			...translation,
		}
	})
}
export const isSSR = () => typeof window === 'undefined'

export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const isTouchDevice = () => {
	if (isSSR()) return false

	let isTouchDevice = false

	if (window.PointerEvent && 'maxTouchPoints' in navigator) {
		isTouchDevice = navigator.maxTouchPoints > 0
	} else if (window.matchMedia) {
		const query = '(pointer:coarse)'
		isTouchDevice = window.matchMedia(query).matches
	} else if (window.TouchEvent || 'ontouchstart' in window) {
		isTouchDevice = true
	}

	return isTouchDevice
}

export const getBreakpoint = () => {
	if (isSSR()) return false

	if (window.matchMedia('(min-width: 1180px)').matches) return 'desktop'
	if (window.matchMedia('(min-width: 768px)').matches) return 'tablet'
	return 'mobile'
}

export const getUrlQueryParams = () => {
	if (isSSR()) return {}
	const params = new URLSearchParams(window.location.search)
	return Object.fromEntries(params.entries())
}
export const nodeLocaleToEnglishLanguage = {
	'en-US': 'American English',
	'fr-CA': 'French',
	'es-MX': 'Spanish',
}

export const shouldDisplayOnSites = (entry, locale) =>
	entry?.displayOnSites.includes(nodeLocaleToEnglishLanguage[locale])

export const trunc = (str, len = 100) => {
	if (typeof str !== 'string') {
		return ''
	}
	if (str.length <= len) {
		return str
	}
	return str.substr(0, len - 1) + '...'
}

export const handleRedirect = ({ entryData, redirects = [] }) => {
	const redirect = redirects?.find(({ fromPath }) => fromPath === `/${entryData?.originalSlug}`)
	const slugsToBypassRedirect = ['home']
	const bypassRedirect = slugsToBypassRedirect?.includes(entryData?.originalSlug)

	if (redirect && !bypassRedirect && !isSSR()) {
		window.location.replace(redirect?.toUrl)
	}
}

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export const isEmailValid = (email) => {
	return emailRegex.test(email)
}

export const parseUrlToSlugAndHref = (url) => {
	const href = url?.match(/^(http|https|mailto|tel):\/\//) ? url : null
	const formattedSlug = url?.match(/^\/.+/) ? url.slice(1) : url
	const slug = href ? null : formattedSlug

	return [slug, href]
}

export const handleScrollToFirstPreorderSection = (event) => {
	if (event) event.preventDefault()
	const preOrderSection = document.querySelector('.product-buy-now-show-case')

	preOrderSection?.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	})

	// pre-order event
	typeof window !== 'undefined' &&
		window.gtag &&
		window.gtag('event', 'pre_order_button_click', {
			event_category: 'Button',
			event_label: 'Preorder button',
		})
}

export const parseQueryString = (query, queryVariable) => {
	const variables = query.split('&')
	for (let i = 0; i < variables.length; i++) {
		const pair = variables[i].split('=')

		if (decodeURIComponent(pair[0]) === queryVariable) {
			return decodeURIComponent(pair[1])
		}
	}
}

export const sanitizeAndAddYoutubeThumbnailUrls = (youtubeVideoUrls) =>
	youtubeVideoUrls?.map((url) => {
		const queryParams = url?.split('?')[1]
		const videoId = parseQueryString(queryParams, 'v')

		return {
			id: videoId,
			url: 'https://www.youtube.com/embed/' + videoId,
			thumbnail: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
		}
	})
