import { useCopy } from '@hooks/useCopy'
import { useSettings } from '@hooks/useSettings'
import ButtonOrLink from '@components/common/ButtonOrLink/ButtonOrLink'

import StoreIcon from '@svg/store.svg'

const FindAStore = () => {
	const { t } = useCopy()
	const settings = useSettings()

	return (
		<div className="module-findAStore grid-default mt-32">
			<div
				className="container-border flex items-center gap-32 rounded-16 bg-midnight px-32 py-24 mobile:flex-col mobile:justify-center"
				data-animation="appearY"
			>
				<StoreIcon />
				<h4 className="ts-4 flex-grow whitespace-pre-line text-white mobile:text-center">{t('store.support')}</h4>
				{settings?.findAStoreUrl && (
					<>
						<ButtonOrLink
							link={{ href: settings.findAStoreUrl, title: t('store.findAStore') }}
							className="mobile:hidden"
							color="white"
							outline
							shape="right"
						/>
						<ButtonOrLink
							link={{ href: settings.findAStoreUrl, title: t('store.findAStore') }}
							className="tablet:hidden desktop:hidden"
							color="white"
							outline
							shape="middle"
						/>
					</>
				)}
			</div>
		</div>
	)
}
export default FindAStore
