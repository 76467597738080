import { Slice } from 'gatsby'
import useVH from 'react-vh'

import CartPanel from '@components/layout/CartPanel/CartPanel'
import { SettingsContext } from '@context/settingsContext'
import { useAnimations } from '@hooks/useAnimations'
import FindAStore from '@modules/FindAStore/FindAStore'
import Newsletter from '@modules/Newsletter/Newsletter'
import useLenisScrollRestore from '@hooks/useLenisScrollRestore'

import Background from './Background'

const Layout = ({ children, data = {}, pageContext = {}, location }) => {
	const settings = Object.assign({}, pageContext.globalSettings, {
		parentPages: pageContext.parentPages,
		enableBreadcrumbs: data.page?.enableBreadcrumbs ?? true,
		breadcrumbs: data.page?.breadcrumbs,
		fullPath: pageContext?.fullPath,
	})

	const el = useAnimations(location)
	useLenisScrollRestore(location)

	useVH()

	return (
		// <PageTransition location={location}>
		<SettingsContext.Provider value={settings}>
			<Background />

			<Slice alias="header" allowEmpty fullPath={settings.fullPath} breadcrumbs={settings.breadcrumbs} />

			<main className="relative">{children}</main>

			<div ref={el}>
				<FindAStore />
				<Newsletter theme="dark" />
				<Slice alias="footer" allowEmpty />
				<CartPanel />
			</div>
		</SettingsContext.Provider>
		// </PageTransition>
	)
}

export default Layout
