import { useEffect, useRef } from 'react'

import cn from 'clsx'
import { gsap } from 'gsap'

import { useLenis } from '@hooks/useLenis'
import Button from '@components/common/Form/Button/Button'
import { useCart } from '@context/cartContext'
import { useCopy } from '@hooks/useCopy'

import CartItem from './subcomponents/CartItem'

const CartPanel = () => {
	const firstMount = useRef(true)
	const overlay = useRef()
	const panel = useRef()
	const { isOpen, closeCart, cart } = useCart()
	const { t } = useCopy()

	const lenis = useLenis()

	useEffect(() => {
		if (!cart.enabled) return

		if (firstMount.current) {
			firstMount.current = false
			return
		}

		if (!lenis) return

		const tl = gsap.timeline()
		if (isOpen) {
			lenis.stop()
			tl.to(overlay.current, { opacity: 1, duration: 1, ease: 'power3.out' }, 0)
			tl.to(panel.current, { x: '0%', duration: 1, ease: 'power3.out' }, 0)
		} else {
			tl.to(overlay.current, { opacity: 0, duration: 1, ease: 'power3.out' }, 0)
			tl.to(panel.current, { x: '100%', duration: 1, ease: 'power3.out' }, 0)
			lenis.start()
		}
		return () => tl.kill()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, cart.enabled])

	if (!cart.enabled) return null

	return (
		<section
			id="component-cartPanel"
			className={cn('fixed inset-0 z-1001', isOpen ? 'pointer-events-auto' : 'pointer-events-none')}
		>
			<div ref={overlay} className="absolute inset-0 bg-black bg-opacity-80 opacity-0" onClick={closeCart} />
			<div
				ref={panel}
				data-lenis-prevent
				className="bg-noise absolute inset-0 left-56 translate-x-100% overflow-y-auto overscroll-contain px-24 pb-32 tablet:left-35% tablet:px-32 desktop:left-55%"
			>
				<div className="CartBtnTextSize tablet:mt-32 desktop:flex">
					<Button
						onClick={closeCart}
						color="orange"
						outline
						className="mt-64 w-full desktop:mt-0 desktop:basis-50%"
						shape="middle"
					>
						{t('cart.continueShopping')}
					</Button>
					<Button
						onClick={() => window.open(cart.webUrl)}
						color="orange"
						className="mt-24 w-full desktop:mt-0 desktop:basis-50%"
						shape="middle"
					>
						{t('cart.proceedToShipping')}
					</Button>
				</div>

				<div className="ts-nav-item-desktop relative mt-32 text-black">
					{t('cart.yourCart')} (
					{cart?.lineItems?.reduce((acc, current) => {
						return acc + current.quantity
					}, 0)}
					)
				</div>

				{/* 
				{t('cart.additionalSubtitle') && (
					<div className="relative mt-32 rounded-8 border-1 border-solid border-midnight p-16">
						<div className="ts-mobile-bold text-midnight">{t('cart.additionalNoteTitle')}</div>
						<div className="mt-16 text-midnight">{t('cart.additionalSubtitle')}</div>
					</div>
				)} 
				*/}

				<ul>
					{cart.lineItems?.map((item, i) => {
						return (
							<div key={i}>
								<div className="separator relative mt-32" />
								<CartItem item={item} isUpdating={cart} closeCart={closeCart} />
							</div>
						)
					})}
				</ul>

				{/* <div className="mt-56 tablet:flex">
					<Input
						color="gray"
						className="tablet:basis-75%"
						inputClassName="text-center"
						placeholder={t('cart.discount')}
						shape="both"
						// error={errors.email}
						type="text"
						// {...register('email', {
						// 	required: 'Email Address is required',
						// 	pattern: { value: EMAIL_REGEXP, message: 'Please enter a valid email address' },
						// })}
					/>
					<Button onClick={() => {}} color="orange" className="w-full mobile:mt-16 tablet:basis-25%" shape="middle">
						{t('cart.applyCta')}
					</Button>
				</div> */}

				<div className="separator relative mt-32" />

				<div className="relative flex flex-col">
					<div className="ts-body mt-32 flex flex-row justify-between text-black">
						<span>{t('cart.subtotal')}</span>
						<span>
							{t('cart.currencySign')}
							{Number(cart.subtotalPriceV2?.amount).toFixed(2)}
						</span>
					</div>
					<div className="ts-body mt-32 flex flex-row justify-between text-black">
						<span>{t('cart.shipping')}</span>
						<span className="uppercase">{t('cart.shippingText')}</span>
					</div>
					<div className="ts-body mt-32 flex flex-row justify-between text-black">
						<span>{t('cart.taxes')}</span>
						<span>
							{t('cart.currencySign')}
							{Number(cart.totalTaxV2?.amount).toFixed(2)}
						</span>
					</div>
					<div className="ts-header mt-32 flex flex-row justify-between text-black">
						<span>{t('cart.total')}</span>
						<span>
							{t('cart.currencySign')}
							{Number(cart.totalPriceV2?.amount).toFixed(2)}
						</span>
					</div>
				</div>
			</div>
		</section>
	)
}

export default CartPanel
