import { forwardRef } from 'react'

import cn from 'clsx'

import { cornerColorVariants } from '.'

const Right = forwardRef(({ color, outline, className }, ref) => {
	return (
		<div className="relative h-100% w-32 overflow-hidden">
			<svg
				ref={ref}
				width="37"
				height="56"
				viewBox="0 0 37 56"
				xmlns="http://www.w3.org/2000/svg"
				className={cn('absolute right-0 top-0', cornerColorVariants[`${color}${outline ? 'Outline' : ''}`], className)}
			>
				<path
					d="M2 54.5L2 1.5L21.806 1.5C25.0438 1.5 27.3698 1.50147 29.1427 1.66267C30.9125 1.82358 31.9199 2.13046 32.6179 2.5974C34.0521 3.55683 35.046 5.04716 35.3805 6.73996C35.5433 7.56382 35.4394 8.61176 34.9077 10.3074C34.3751 12.0061 33.4825 14.1541 32.2381 17.1431L21.2221 43.6029C19.3983 47.9837 18.6134 49.8111 17.3846 51.1371C16.26 52.3506 14.8646 53.2812 13.3121 53.853C11.6157 54.4778 9.62696 54.5 4.8817 54.5L2 54.5Z"
					strokeWidth="3"
				/>
			</svg>
		</div>
	)
})

export default Right
