import cn from 'clsx'

import { GatsbyImageWrapper, VanillaImageWrapper } from './subcomponents'

const Image = ({ src, alt, className, useVanillaImage = false, objectFit, objectPosition, ...rest }) => {
	const useGatsbyImage = !useVanillaImage && src?.gatsbyImageData
	const defaultAlt = src?.description || src?.title

	if (!src || Object.keys(src).length === 0) {
		return null
	}

	return (
		<>
			{useGatsbyImage ? (
				<GatsbyImageWrapper
					src={src}
					alt={alt === '' ? '' : alt || defaultAlt}
					className={cn('image-wrapper', className)}
					objectFit={objectFit}
					objectPosition={objectPosition}
					{...rest}
				/>
			) : (
				<VanillaImageWrapper src={src} alt={alt === '' ? '' : alt || defaultAlt} {...rest} className={className} />
			)}
		</>
	)
}

export default Image
