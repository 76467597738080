import { isSSR } from '@utils'

const lenis = {
	stopped: false,
	start() {
		if (isSSR() || !this.stopped) return
		document.body.classList.remove('lenis-stopped')
		this.stopped = false
	},
	stop() {
		if (isSSR() || this.stopped) return
		document.body.classList.add('lenis-stopped')
		this.stopped = true
	},
}

if (typeof window !== 'undefined') {
	document.body.classList.add('lenis')
}
// If we need to revert smooth scroll, let's export the hook from @studio-freight/react-lenis
export const useLenis = () => lenis
