import { useEffect, useRef, useCallback } from 'react'

import useResizeObserver from '@react-hook/resize-observer'
import cn from 'clsx'

import { fontColorVariants } from '@components/common/Form/Subcomponents'
import { getBreakpoint } from '@utils'

import ButtonLoader from './ButtonLoader'

const themeVariants = {
	orange: 'fill-orange stroke-orange group-hover:fill-transparent',
	orangeOutline: 'fill-transparent stroke-orange group-hover:fill-orange',
	primary: 'fill-primary stroke-primary group-hover:fill-transparent',
	primaryOutline: 'fill-transparent stroke-primary group-hover:fill-primary',
	secondary: 'fill-secondary stroke-secondary group-hover:fill-transparent',
	secondaryOutline: 'fill-transparent stroke-secondary group-hover:fill-secondary',
	white: 'fill-white stroke-white group-hover:fill-transparent',
	whiteOutline: 'fill-transparent stroke-white group-hover:fill-white',
	black: 'fill-black stroke-black group-hover:fill-transparent',
	blackOutline: 'fill-transparent stroke-black group-hover:fill-black',
	gray: 'fill-gray stroke-gray group-hover:fill-orange group-hover:stroke-orange',
	error: 'fill-white stroke-red',
}

const ButtonContent = ({
	children,
	shape = 'left',
	color = 'orange',
	outline,
	className,
	classNameContentDiv,
	disabled,
	loading,
	classNameContent,
	isPaginationButton,
	inverseOutline,
	...rest
}) => {
	const lowerShape = shape?.toLocaleLowerCase()
	const lowerColor = color?.toLocaleLowerCase()

	let theme = `${lowerColor}`
	let hasDropShadow = false
	if (lowerColor === 'orange' || lowerColor === 'gray' || lowerColor === 'error' || lowerColor === 'secondary') {
		theme += `${outline ? 'Outline' : ''}`
		hasDropShadow = !outline
	} else {
		// Not using inverse Outline for now but keeping it in case we need to change
		// With Inverse
		// theme += `${inverseOutline ? (outline ? '' : 'Outline') : outline ? 'Outline' : ''}`
		// Genreal
		theme += `${outline ? '' : 'Outline'}`
		hasDropShadow = outline
	}
	const realShape = getBreakpoint() !== 'mobile' ? lowerShape : 'middle'
	const el = useRef()
	const content = useRef()
	const svg = useRef()

	const createShape = useCallback(() => {
		let minus = lowerShape === 'middle' ? 60 : 54
		let width = content.current.offsetWidth - minus

		let svgContent = '<path stroke-width="3" shape-rendering="geometricPrecision" d="',
			start = 0

		if (realShape !== 'left') {
			const borderLeft = `M31 0
			H30.8689
			C29.6455 0.00001 28.7155 0.00059 27.9509 0.02733
			C27.137 0.0558 26.5104 0.11392 25.9166 0.23256
			C22.81 0.85325 20.0748 2.67725 18.3077 5.3067
			C17.6456 6.29193 17.1498 7.48296 16.1581 9.8649
			L4.32803 38.2803
			C1.86132 44.2053 0.627967 47.1677 1.09886 49.5508
			C1.51055 51.6342 2.73377 53.4685 4.49895 54.6493
			C6.51797 56 9.72693 56 16.1448 56
			H31`

			start = 31
			svgContent += borderLeft
		} else {
			const borderLeft = `
			M 24 0
			H 8
			C 3.5817 0 0 3.5817 0 8
			V 48
			C 0 52.4183 3.5817 56 8 56
			H 24
			`
			start = 24
			svgContent += borderLeft
		}

		svgContent += `H${31 + width}`

		width = width + 31
		if (realShape !== 'right') {
			const borderRight = `M${2.28895e-7 + width} 56
			H${0.0821087 + width}
			C${1.30753 + width} 56 ${2.23902 + width} 55.9994 ${3.00492 + width} 55.9726
			C${3.82015 + width} 55.9442 ${4.44777 + width} 55.886 ${5.04254 + width} 55.7674
			C${8.15427 + width} 55.1467 ${10.8939 + width} 53.3227 ${12.6639 + width} 50.6933
			C${13.327 + width} 49.708 ${13.8237 + width} 48.517 ${14.817 + width} 46.1351
			L${26.6665 + width} 17.7197
			C${29.1373 + width} 11.7947 ${30.3726 + width} 8.83223 ${29.901 + width} 6.44918
			C${29.4886 + width} 4.36573 ${28.2634 + width} 2.53148 ${26.4953 + width} 1.35065
			C${24.473 + width} 0 ${21.2588 + width} 0 ${14.8303 + width} 0
			L${2.28895e-7 + width} 0`

			width = width - start
			svgContent += borderRight
		} else {
			const borderRight = `
			M ${0 + width} 56
			H ${16 + width}
			C ${20.4183 + width} 56 ${24 + width} 52.4183 ${24 + width} 48
			V 8
			C ${24 + width} 3.5817 ${20.4183 + width} 0 ${16 + width} 0
			H ${0 + width}`

			width = width - start
			svgContent += borderRight
		}
		svgContent += `H${start}`
		svgContent += `M${start} 56`

		// const centerTransform = `transform(${}, 0)`

		svgContent += '" />'

		svg.current.innerHTML = svgContent
	}, [lowerShape, realShape])

	useEffect(() => {
		createShape()
	}, [createShape])

	useResizeObserver(el, createShape)
	// { 'drop-shadow-button': !outline, 'opacity-50': disabled },
	// To remove shadow on buttons

	return (
		<div
			className={cn(
				'group relative flex h-56 cursor-pointer items-center mobile:w-full',
				'transition duration-500 ease-immgPosIn',
				{ 'drop-shadow-button hover:drop-shadow-buttonHover': hasDropShadow, 'opacity-50': disabled },
				className,
				classNameContentDiv
			)}
			ref={el}
			{...rest}
		>
			<svg
				className={cn(
					'absolute inset-0 z-1 h-full w-full overflow-visible transition duration-500',
					themeVariants[theme]
				)}
				xmlns="http://www.w3.org/2000/svg"
				ref={svg}
				fill="transparent"
			/>

			<div
				ref={content}
				className={cn(
					'ts-button z-2 flex h-full w-full items-center justify-center gap-16 py-16 tabular-nums leading-5',
					{ 'mobile:flex-grow': !isPaginationButton },
					isPaginationButton ? 'px-24' : 'px-24 tablet:px-32',
					fontColorVariants[theme],
					classNameContent
				)}
			>
				<span className={cn({ 'opacity-0': loading }, 'transition duration-500 mobile:text-center tablet:w-max')}>
					{children}
				</span>
				{loading && <ButtonLoader color={lowerColor} />}
			</div>
		</div>
	)
}

export default ButtonContent
