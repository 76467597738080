const Background = () => (
	<div className="fixed inset-0 flex justify-between bg-bodyColor">
		<div className="text-primary" />
		<div className="border-r-1 border-solid border-borderBorder text-secondary tablet:border-r-2" />
		<div className="border-r-1 border-solid border-borderBorder tablet:border-r-2" />
		<div className="border-r-1 border-solid border-borderBorder tablet:border-r-2" />
		<div />
	</div>
)

export default Background
