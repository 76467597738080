import cn from 'clsx'
import { graphql } from 'gatsby'

import { ExternalLink, InternalLink } from '@components/common/Link/subcomponents'

import * as style from './Link.module.css'

const linkClasses = 'relative transition-colors duration-250'

const Link = ({
	id,
	children,
	title,
	internalNickname,
	internalOrExternal,
	href,
	target,
	analytics,
	button = false,
	externalLinkIcon,
	underline = !button,
	className,
	anchorLinkTag,
	...rest
}) => {
	if (internalOrExternal && target)
		return (
			<InternalLink
				target={target}
				className={cn(!button && linkClasses, className, underline && [style.underline], className)}
				{...rest}
			>
				{children || title}
			</InternalLink>
		)
	else if (href)
		return (
			<ExternalLink
				href={href}
				className={cn(!button && linkClasses, className, underline && [style.underline], className)}
				externalLinkIcon={externalLinkIcon}
				isAnchorLink={anchorLinkTag}
				{...rest}
			>
				{children || title}
			</ExternalLink>
		)
	return (
		<span className={cn(!button && linkClasses, 'cursor-not-allowed', className)} disabled alt={title}>
			{children || title}
		</span>
	)
}

export default Link

export const query = graphql`
	fragment PageLinkFragment on ContentfulLink {
		id
		title
		internalOrExternal
		href
		target {
			... on ContentfulPage {
				title
				fullPath
			}
			... on ContentfulAutoGeneratedPage {
				title
				fullPath
			}
			... on ContentfulProduct {
				title
				fullPath
			}
			... on ContentfulArticle {
				title
				fullPath
			}
			... on ContentfulCategoryGroup {
				title
				fullPath
			}
			... on ContentfulCategory {
				title
				fullPath
			}
			... on ContentfulIp {
				title
				fullPath
			}
			... on ContentfulCardSeries {
				title
				fullPath
			}
		}
		anchorLinkTag
	}
`
